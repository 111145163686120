import { LineupSectionItemProps } from "@/components/domains/lp/LineupSection/LineupSectionItem";

import ImgThumbBBChocolateOmote from "$/images/pkg/thumb_bb_chocolate_omote.png";
import ImgThumbBBCinnamonOmote from "$/images/pkg/thumb_bb_cinnamon_omote.png";
import ImgThumbBBCurryOmote from "$/images/pkg/thumb_bb_curry_omote.png";
import ImgThumbBBKoshianOmote from "$/images/pkg/thumb_bb_koshian_omote.png";
import ImgThumbBBMapleOmote from "$/images/pkg/thumb_bb_maple_omote.png";
import ImgThumbBBRichOmote from "$/images/pkg/thumb_bb_rich_omote.png";
import ImgThumbBBSlicePlainOmote from "$/images/pkg/thumb_bb_slice_plain_omote.png";
import ImgThumbBBSliceRaisinOmote from "$/images/pkg/thumb_bb_slice_raisin_omote.png";
import ImgThumbBCCheeseOmote from "$/images/pkg/thumb_bc_cheese_omote.png";
import ImgThumbBCCocoaOmote from "$/images/pkg/thumb_bc_cocoa_omote.png";
import ImgThumbBCCoconutOmote from "$/images/pkg/thumb_bc_coconut_omote.png";
import ImgThumbBCEarlgreyOmote from "$/images/pkg/thumb_bc_earlgrey_omote.png";
import ImgThumbBCPepperOmote from "$/images/pkg/thumb_bc_pepper_omote.png";
import ImgThumbBPMOmote from "$/images/pkg/thumb_bpm_omote.png";

export const items: LineupSectionItemProps[] = [
  {
    category: "BREAD",
    title: "チョコレート",
    imageSrc: ImgThumbBBChocolateOmote,
    description: "しっとりチョコが折り込まれた、やわもち食感のマーブルチョコパン。",
  },
  {
    category: "BREAD",
    title: "メープル",
    imageSrc: ImgThumbBBMapleOmote,
    description: "まろやかな甘みにほっとする、スティックタイプのメープルパン。",
  },
  {
    category: "BREAD",
    title: "シナモン",
    imageSrc: ImgThumbBBCinnamonOmote,
    description: "本格シナモンの豊かな香りがたのしめるシナモン味。",
  },
  {
    category: "BREAD",
    title: "こしあん",
    imageSrc: ImgThumbBBKoshianOmote,
    description: "北海道産小豆の上品な甘み広がるしっとり生地のあんぱん。",
  },
  {
    category: "BREAD",
    title: "ミニ食パン・レーズン",
    imageSrc: ImgThumbBBSliceRaisinOmote,
    description: "みずみずしいレーズンの甘みがじゅわっと広がる、サクもち食感のミニ食パン。",
  },
  {
    category: "BREAD",
    title: "カレー",
    imageSrc: ImgThumbBBCurryOmote,
    description: "揚げずに焼いて仕上げた、やわもち食感の濃厚カレーパン。",
  },
  {
    category: "BREAD",
    title: "リッチ",
    imageSrc: ImgThumbBBRichOmote,
    description: "ふっくらとした生地にまろやかな甘み広がる、芳醇なあじわいのロールパン。",
  },
  {
    category: "BREAD",
    title: "ミニ食パン・プレーン",
    imageSrc: ImgThumbBBSlicePlainOmote,
    description: "やさしい甘みでアレンジ楽しい全粒粉ミニ食パン。",
  },
];

export const itemsMore: LineupSectionItemProps[] = [
  {
    category: "Pancake Mix",
    title: "パンケーキミックス",
    imageSrc: ImgThumbBPMOmote,
    description: "ふんわりしっとり食感のパンケーキミックス。",
  },
  {
    category: "Cookies",
    title: "ココア",
    imageSrc: ImgThumbBCCocoaOmote,
    description: "ココアがふんわり香る、落ち着く大人のクッキー。",
  },

  {
    category: "Cookies",
    title: "アールグレイ",
    imageSrc: ImgThumbBCEarlgreyOmote,
    description: "アールグレイの香り引き立つ、気分ほぐれるクッキー。",
  },
  {
    category: "Cookies",
    title: "ココナッツ",
    imageSrc: ImgThumbBCCoconutOmote,
    description: "粗挽きココナッツの風味と香りがひとくちごとに広がる、ほんのり甘いクッキー。",
  },
  {
    category: "Cookies",
    title: "ペッパー",
    imageSrc: ImgThumbBCPepperOmote,
    description: "お酒のお供に！ザクザク食感が特徴のピリッと香るブラックペッパー。",
  },
  {
    category: "Cookies",
    title: "チーズ",
    imageSrc: ImgThumbBCCheeseOmote,
    description: "おやつにサクッと！ザクザク食感が特徴のやさしく香ばしいチェダーチーズ。",
  },
];
