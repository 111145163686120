"use client";

import { useState } from "react";

import clsx from "clsx";

import { LineupSectionItem } from "@/components/domains/lp/LineupSection/LineupSectionItem";
import { LineupSectionItemSp } from "@/components/domains/lp/LineupSection/LineupSectionItemSp";

import { items, itemsMore } from "./consts";
import styles from "./LineupSection.module.scss";

export function LineupSection(): React.ReactNode {
  const [showMore, setShowMore] = useState(false);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="text__center">
            <h2 className="text__xl text__bold">ラインナップ</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className={clsx(styles.flavorLineup, styles.hideInSP)}>
            {items.map((item) => (
              <div className="col-12 col-m-3 pd__top__l" key={item.title}>
                <LineupSectionItem {...item} />
              </div>
            ))}
          </div>
          <div className={clsx(styles.flavorLineup, styles.hideInPC)}>
            {items.map((item) => (
              <LineupSectionItemSp key={item.title} {...item} />
            ))}
          </div>
          {showMore && (
            <>
              <div className={clsx(styles.flavorLineup, styles.hideInSP)}>
                {itemsMore.map((item) => (
                  <div key={item.title} className="col-12 col-m-3 pd__top__l">
                    <LineupSectionItem {...item} />
                  </div>
                ))}
              </div>
              <div className={clsx(styles.flavorLineup, styles.hideInPC)}>
                {itemsMore.map((item) => (
                  <LineupSectionItemSp key={item.title} {...item} />
                ))}
              </div>
            </>
          )}
          <div className="col-12">
            <div className="text__center mg__top__l">
              {showMore ? (
                <button
                  onClick={() => setShowMore(false)}
                  className="btn btn__l gray inline round text__m text__black"
                >
                  閉じる
                </button>
              ) : (
                <button
                  onClick={() => setShowMore(true)}
                  className="btn btn__l gray inline round text__m text__black"
                >
                  もっとみる
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
