"use client";

import { useState, useEffect } from "react";

import clsx from "clsx";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { NextImageLP } from "@/components/displays";
import { PRODUCT_SALE_PARAMETER } from "@/configs/system";
import { ProductsBySkuCalcPriceModel } from "@/models/product/type";
import { formatCurrency } from "@/utils/currency";

import styles from "./SetPackListsSection.module.scss";

interface SetPackListsSectionProps {
  productsBySkuCalcPrice: ProductsBySkuCalcPriceModel[];
}

export function SetPackListsSection({
  productsBySkuCalcPrice,
}: SetPackListsSectionProps): React.ReactNode {
  const [mounted, setMounted] = useState(false);

  /**
   * slidesPerViewを1以上いすると、JS側での計算は必要となり、mountedした後SwiperSlideにstyleが適用され、layout shiftが発生する
   * そのため、mountedした後のstyleと似ているstyleをSwiperSlideに適用することで、layout shiftを防ぐ
   */
  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <>
      <div className="clear__sp">
        <div className="row">
          {productsBySkuCalcPrice.map((product, index) => {
            return (
              <div key={index} className="col-12 col-m-4">
                <div
                  className={clsx(styles.threePoints, {
                    mg__bottom__m: index === 0,
                  })}
                >
                  <p className="text__l text__bold mg__bottom__s text__center">{product.name}</p>
                  <div
                    className={clsx(
                      "pd__top__m",
                      "pd__bottom__m",
                      "text__center",
                      "mg__bottom__s",
                      styles.priceOutline
                    )}
                  >
                    <span
                      className={clsx(
                        "tag",
                        "red",
                        "radius",
                        "text__white",
                        "text__s",
                        styles.pricePercent
                      )}
                    >
                      友だち紹介限定価格
                    </span>
                    <br />
                    <del className="text__s">
                      通常価格{formatCurrency(product.defaultPrice)}（送料・税込）
                    </del>
                    <br />
                    <span className="text__red text__xl text__bold">
                      {formatCurrency(product.totalPrice + PRODUCT_SALE_PARAMETER.inviteDiscount)}
                    </span>
                    <span className="text__s text__red">（送料・税込）</span>
                    <p className="text__s text__gray__dark text__left mg__top__s">
                      ※継続コース初回のみ ※2回目以降のご購入から
                      {formatCurrency(product.secondTotalPrice)}
                      （送料・税込）※1回目お届け以降、いつでも内容変更、解約が可能
                    </p>
                  </div>
                  <div className="pd__top__m">
                    {product.images?.map((image, index) => (
                      <NextImageLP
                        key={index}
                        alt={product.name}
                        src={image.src}
                        width={image.width}
                        className={clsx(styles.fullImages)}
                      />
                    ))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="clear__pc fix-container-margin">
        <Swiper
          // SwiperSlideにおいて、mountの前styleがでキャッシュされている？可能性があり、
          // mount後にステートをリフレッシュするため、keyを変更する
          key={mounted ? "mounted" : "unmounted"}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          spaceBetween={20}
          slidesPerView={1.2}
          centeredSlides
        >
          {productsBySkuCalcPrice.map((product, index) => {
            return (
              <SwiperSlide key={index} className={styles.swiperWrapper}>
                <div
                  className={clsx(styles.threePoints, {
                    mg__bottom__m: index === 0,
                  })}
                >
                  <p className="text__l text__bold mg__bottom__s text__center">{product.name}</p>
                  <div
                    className={clsx(
                      "pd__top__m",
                      "pd__bottom__m",
                      "text__center",
                      "mg__bottom__s",
                      styles.priceOutline
                    )}
                  >
                    <span
                      className={clsx(
                        "tag",
                        "red",
                        "radius",
                        "text__white",
                        "text__s",
                        styles.pricePercent
                      )}
                    >
                      友だち紹介限定価格
                    </span>
                    <br />
                    <del className="text__s">
                      通常価格{formatCurrency(product.defaultPrice)}（送料・税込）
                    </del>
                    <br />
                    <span className="text__red text__xl text__bold">
                      {formatCurrency(product.totalPrice + PRODUCT_SALE_PARAMETER.inviteDiscount)}
                    </span>
                    <span className="text__s text__red">（送料・税込）</span>
                    <p className="text__s text__gray__dark text__left mg__top__s">
                      ※継続コース初回のみ ※2回目以降のご購入から
                      {formatCurrency(product.secondTotalPrice)}
                      （送料・税込）※1回目お届け以降、いつでも内容変更、解約が可能
                    </p>
                  </div>
                  <div className="pd__top__m pd__bottom__m">
                    {product.images?.map((image, index) => (
                      <NextImageLP
                        key={index}
                        alt={product.name}
                        src={image.src}
                        width={image.width}
                        className={clsx(styles.fullImages)}
                      />
                    ))}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
}
